import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTriggers } from '../../../hooks/navigation';
//import '../instructor-pro-demo.webflow.css';
import { notifyParentOnEmbedClose } from '../../../utils/auth';
//import './InstructorHeader.css';
import { navigationActions } from '../../../lib/redux/navigation/slice';

const InstructorHeader = () => {
  const auth = useSelector((state) => state.auth?.data);
  const dispatch = useDispatch();
  const _instructorMode = useSelector(
    (state) => state.navigation.instructorMode
  );
  const { data, darkMode } = useSelector((state) => state.navigation);
  const mentors = useSelector((state) => state.mentors);
  const [settingsIsOpen, setSettingsIsOpen] = useState(false);
  const [instructorMode, setInstructorMode] = useState(_instructorMode);
  const {
    handleMentorsMenuBtnClick,
    handleFeaturedMentorClick,
    handlePromptGalleryBtnClick,
    handleHelpMenuBtnClick,
    handleUserTypeSwitchChange,
    handleThemeSwicherBtnClick,
    handleMentorToParentCollapse,
    handleMobileHeaderMenuToggleClick,
  } = useTriggers();
  useEffect(() => {
    if (!auth?.tenant?.is_admin) {
      setInstructorMode(false);
    }
  }, [auth?.tenant?.is_admin]);

  useEffect(() => {
    const _settingsClickHandler = () => {
      setSettingsIsOpen(false);
    };
    window.addEventListener('click', _settingsClickHandler);
    return () => {
      window.removeEventListener('click', _settingsClickHandler);
    };
  });

  const _openSettings = (event) => {
    event.stopPropagation();
    setSettingsIsOpen(true);
  };

  const _openHistory = () => {
    dispatch(
      navigationActions.navigationUpdated({
        ...data,
        mobileSidebar: true,
      })
    );
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        @import url(/instructor-assets/css/instructor-pro-demo.webflow.css);
        @import url(/instructor-assets/css/InstructorHeader.css);
        `,
        }}
      />
      <div className="w-layout-hflex navbar">
        <div className="w-layout-hflex flex-block">
          <div style={{ display: 'flex' }}>
            <img
              src={
                mentors?.mentor?.settings?.profile_image ??
                '/images/ai-academy-p-500.png'
              }
              style={{ borderRadius: '50%' }}
              loading="lazy"
              width="30"
              data-w-id="1efc8ba7-4724-d580-9c05-5f2c9d259910"
              alt=""
              prop-data-value-src="mainLogo"
              prop-attr-names="src"
              className="main-logo"
            />
            <p
              style={{
                marginLeft: '15px',
                fontWeight: 'bold',
                color: 'var(--bodyicon)',
              }}
            >
              {mentors?.mentor?.settings?.mentor ?? ''}
            </p>
          </div>

          {false && (
            <div className="w-layout-hflex flex-block-63">
              <div className="text-block-58">
                <strong>Instructor</strong>
              </div>
              <div className="switch w-embed">
                <label className="switch learner-instructor-switch">
                  <input
                    defaultChecked={instructorMode}
                    onClick={handleUserTypeSwitchChange}
                    type="checkbox"
                  />
                  <span className="slider round">
                    <img
                      className="left-img"
                      alt=""
                      src="https://iblai-static-assets.s3.amazonaws.com/mentor/img/presentation.svg"
                    />
                    <img
                      className="right-img"
                      alt=""
                      src="https://iblai-static-assets.s3.amazonaws.com/mentor/img/graduation-cap.svg"
                    />
                  </span>
                </label>
                <style
                  dangerouslySetInnerHTML={{
                    __html: `  
            .switch {
              position: relative;
              display: inline-block;
              width: 46px;
              height: 20px;
              margin-bottom: 0px !important;
            }
            /* Hide default HTML checkbox */
            .switch input {
              opacity: 0;
              width: 0;
              height: 0;
            }
            /* The slider */
            .slider {
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ccc;
              -webkit-transition: 0.2s;
              transition: 0.2s;
            }
            .slider:before {
              position: absolute;
              content: "";
              height: 24px;
              width: 24px;
              left: 0px;
              bottom: -2px;
              background-color: #bababa;
              -webkit-transition: 0.1s;
              transition: 0.1s;
            }
            input:not(:checked) + .slider img.right-img {
              display: none;
            }
            input:checked + .slider img.left-img {
              display: none;
            }
            input:checked + .slider:before {
              background-color: #2467eb;
            }
            .slider img {
              filter: brightness(0) invert(1) !important;
              width: 16px;
              height: 21px;
              position: absolute;
            }
            .slider img.left-img {
              left: 3px;
              bottom: 0px;
            }
            .slider img.right-img {
              right: 0px;
              bottom: -1px;
              width: 16px;
            }
            input:checked + .slider {
              background-color: rgba(36, 103, 235, 0.6);
            }
            input:focus + .slider {
              box-shadow: 0 0 1px rgba(36, 103, 235, 0.6);
            }
            input:checked + .slider:before {
              -webkit-transform: translateX(26px);
              -ms-transform: translateX(26px);
              transform: translateX(26px);
            }
            /* Rounded sliders */
            .slider.round {
              border-radius: 15px;
            }
            .slider.round:before {
              border-radius: 50%;
            }
            .input-checked-label {
              color: rgb(36, 103, 235) !important;
            }`,
                  }}
                ></style>
              </div>
              <div className="text-block-59">
                <strong className="bold-text-9">Learner</strong>
              </div>
            </div>
          )}
        </div>
        <div className="w-layout-hflex navbar-right-side-block">
          {/*<div*/}
          {/*  //   onClick={this.toggleChatHistoryComponent.bind(this)}*/}
          {/*  role="button"*/}
          {/*  data-w-id="12a8af40-503f-718a-38f5-f04d487bece9"*/}
          {/*  className="navbar-icon"*/}
          {/*  onClick={_openHistory}*/}
          {/*>*/}
          {/*  history*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  data-hover="false"*/}
          {/*  data-delay="0"*/}
          {/*  className="dropdown-3 w-dropdown"*/}
          {/*  style={{ zIndex: 901 }}*/}
          {/*>*/}
          {/*  <div*/}
          {/*    // onClick={this.toggleAccountComponent.bind(this)}*/}
          {/*    className={`dropdown-toggle-3 w-dropdown-toggle ${settingsIsOpen ? 'w--open' : ''}`}*/}
          {/*    style={{ paddingRight: '0px' }}*/}
          {/*    onClick={(event) => _openSettings(event)}*/}
          {/*  >*/}
          {/*    <div role="button" className="navbar-icon-copy">*/}
          {/*      settings*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <nav*/}
          {/*    onClick={(event) => event.stopPropagation()}*/}
          {/*    class={`dropdown-list-3 w-dropdown-list ${settingsIsOpen ? 'w--open' : ''}`}*/}
          {/*  >*/}
          {/*    <aside className="w-layout-hflex flex-block-31">*/}
          {/*      <div className="text-block-102-copy">account_circle</div>*/}
          {/*      <div className="text-block-27">*/}
          {/*        {JSON.parse(localStorage.getItem('userData'))['user_fullname']}*/}
          {/*      </div>*/}
          {/*    </aside>*/}
          {/*    /!*<aside*!/*/}
          {/*    /!*  className="w-layout-hflex theme-toggle"*!/*/}
          {/*    /!*  onClick={handleThemeSwicherBtnClick}*!/*/}
          {/*    /!*>*!/*/}
          {/*    /!*  <div id="themeIconText" className="darkmode-icon">*!/*/}
          {/*    /!*    {darkMode ? 'light_mode' : 'dark_mode'}*!/*/}
          {/*    /!*  </div>*!/*/}
          {/*    /!*  <div id="themeTextId" className="themetext">*!/*/}
          {/*    /!*    {darkMode ? 'Light' : 'Dark'} Mode*!/*/}
          {/*    /!*  </div>*!/*/}
          {/*    /!*</aside>*!/*/}
          {/*    /!*<aside className="w-layout-hflex flex-block-31">*!/*/}
          {/*    /!*  <div className="text-block-102-copy">help_center</div>*!/*/}
          {/*    /!*  <div className="text-block-27">Help Center</div>*!/*/}
          {/*    /!*</aside>*!/*/}
          {/*    /!*<aside className="w-layout-hflex flex-block-31">*!/*/}
          {/*    /!*  <div className="text-block-102-copy">info</div>*!/*/}
          {/*    /!*  <div className="text-block-27">About Us</div>*!/*/}
          {/*    /!*</aside>*!/*/}
          {/*    /!* <aside className="w-layout-hflex flex-block-31">*/}
          {/*      <div className="text-block-102-copy">logout</div>*/}
          {/*      <div className="text-block-28">Log Out</div>*/}
          {/*    </aside> *!/*/}
          {/*  </nav>*/}
          {/*</div>*/}
          {/* <div
          className="w-layout-hflex flex-block-105"
          style={{
            border: '1px solid var(--underline-divider)',
            borderRadius: '50%',
            padding: '2px',
          }}
        >
          <img
            alt=""
            // src={appData.userMetaData.profile_image.image_url_full}
            loading="lazy"
            className="left-sidebar-bottom-btn-user-icon"
            style={{
              width: '25px',
              height: '25px',
              borderRadius: '50%',
            }}
          />
        </div> */}
          <div
            role="button"
            data-w-id="3cbc0601-0244-0454-3c26-8cdb8f826659"
            className="navbar-icon-close"
            onClick={() => notifyParentOnEmbedClose()}
            style={{
              border: '1px solid var(--underline-divider)',
              borderRadius: '50%',
            }}
          >
            close
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructorHeader;
